import { useContext } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Input from "../../../components/forms/Input";
import TextArea from "../../../components/forms/TextArea";
import Select from "../../../components/forms/Select";
import { useAppSelector } from "../../../store/hooks";
import Button from "../../../components/misc/Button";
import { DetailProductContext } from "../DetailProductContainer";
import { SelectInterface } from "../../../interfaces/InterfacesLocal";
import { formatCurrency } from "../../../utils/helpers";
import FacebookStyleImages from "../../../components/misc/Images/FacebookStyleImages";
import { FaRegFilePdf } from "react-icons/fa";
import reportDownloadHandler from "../../../reports/helpers/reportDownloadHandler";
import AsyncComboBox from "../../../components/forms/AsyncCombobox";
import useServer from "../../../api/useServerMain";
import Toggle from "../../../components/forms/Toggle";
import InputDuration from "../../../components/forms/InputDuration";
import InputColor from "../../../components/forms/InputColor";

const Details = () => {
  
  const { product, updateProduct, updateStockProductState } =
    useContext(DetailProductContext);
  const { business } = useAppSelector((state) => state.init);
  const { productCategories, measures, salesCategories } = useAppSelector(
    (state) => state.nomenclator
  );
  const { control, handleSubmit, watch } = useForm();
  const { allowRoles: verifyRoles, denyRoles } = useServer();

  const onSubmit: SubmitHandler<Record<string, string | number | boolean>> = (
    data
  ) => {
    data.hasDurationEdit = true;
    updateProduct && updateProduct(product?.id, data, updateStockProductState);
  };

  const updateImages = (items: Record<string, any>, callback: Function) => {
    callback();
    updateProduct!(product?.id, items, updateStockProductState);
  };

  const precision = Number(
    business?.configurationsKey.find(
      (item) => item.key === "precission_after_coma"
    )?.value
  );

  //------------------------------------------------------------------------

  const selectSalesCategory: SelectInterface[] = [];
  salesCategories.map((cat) =>
    selectSalesCategory.push({
      name: cat.name,
      id: cat.id,
    })
  );
  selectSalesCategory.push({
    name: "Sin categoría",
    id: null,
  });

  const selectStockCategory: SelectInterface[] = [];
  productCategories?.map((cat) =>
    selectStockCategory.push({
      name: cat.name,
      id: cat.id,
    })
  );
  selectStockCategory.push({
    name: "Sin categoría",
    id: null,
  });

  const selectMeasure: SelectInterface[] = [];
  measures.map((item) =>
    selectMeasure.push({
      id: item.code,
      name: item.value,
    })
  );

  //----------------------------------------------------------------------------------------------------

  //Calculate cost by supplies and fixed cost ----------------------------------------------------------------
  const totalCostCalculated = () => {
      return (
        (product?.fixedCosts.reduce(
          (total, value) => total + (value.type === "percent"? value.costAmount / 100 * (product?.netCost ?? 0) : value.costAmount),
          0
        ) ?? 0) +
        (product?.supplies.reduce(
          (total, value) => total + value.quantity * value.supply.averageCost,
          0
        ) ?? 0) +
        (product?.supplies.length === 0? (product?.netCost ?? 0) : 0)
      ) / (product?.performance ?? 1);
  };

  //-------------------------------------------------------------------------------------------

  const adminRole = verifyRoles([
    "ADMIN",
    "MANAGER_COST_PRICES",
    "PRODUCT_PROCESATOR",
  ]);

  const hasDuration = watch("hasDuration") ?? product?.hasDuration;


  return (
    <div>
      <div className="relative grid grid-cols-5 gap-3 h-[34rem]">
        <FacebookStyleImages
          className="flex border border-gray-300 rounded col-span-2 h-full w-full"
          previewDefault={product?.images.map((item) => ({
            id: item.id,
            src: item.src,
            hash: item.blurHash,
          }))}
          submit={updateImages}
          disabled={!adminRole}
        />
        <div className="border border-gray-300 p-2 rounded col-span-3 overflow-auto scrollbar-none">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-stretch h-full"
          >
            <div className="flex flex-row justify-between">
              <div className="">
                <p className="text-gray-600 text-start">
                  {"Código universal: "}
                  <span className="font-semibold">{product?.salesCode}</span>
                </p>
                {product?.externalId && (
                  <p className="text-gray-600 text-start">
                    {"Referencia externa: "}
                    <span className="font-semibold">{product?.externalId}</span>
                  </p>
                )}
              </div>
              <Button
                color="white"
                name="Exportar"
                textColor="gray-500"
                icon={<FaRegFilePdf className="h-5 text-gray-500" />}
                action={() => {
                  reportDownloadHandler(
                    "Ficha de producto",
                    "product_sheet",
                    business!,
                    product
                  );
                }}
                outline
              />
            </div>
            <div className="flex-col">
              <Input
                label="Nombre"
                name="name"
                control={control}
                placeholder="Nombre del producto"
                rules={{ required: "Este campo es requerido" }}
                defaultValue={product?.name}
                disabled={!adminRole}
              />

              {[
                "STOCK",
                "MENU",
                "SERVICE",
                "ADDON",
                "COMBO",
                "VARIATION",
              ].includes(product?.type ?? "") && (
                <Select
                  label="Categoría de Venta"
                  data={selectSalesCategory}
                  name="salesCategoryId"
                  control={control}
                  defaultValue={product?.salesCategory?.id}
                  disabled={!adminRole}
                />
              )}

              {["STOCK", "RAW", "MANUFACTURED", "WASTE", "ASSET"].includes(
                product?.type ?? ""
              ) && (
                <Select
                  label="Categoría de almacén"
                  data={selectStockCategory}
                  name="productCategoryId"
                  control={control}
                  defaultValue={product?.productCategory?.id}
                  disabled={!adminRole}
                />
              )}

              {(["RAW", "MANUFACTURED", "WASTE"].includes(
                product?.type ?? ""
              ) ||
                (product?.type === "STOCK" && product.saleByWeight)) && (
                <Select
                  label="Unidad de medida"
                  data={selectMeasure}
                  name="measure"
                  control={control}
                  rules={{ required: "Este campo es requerido" }}
                  defaultValue={product?.measure}
                  disabled={!adminRole}
                />
              )}

              {business?.subscriptionPlan.code !== "FREE" && (
                <AsyncComboBox
                  name="supplierId"
                  dataQuery={{
                    url: "/administration/supplier",
                    defaultParams: { all_data: true },
                  }}
                  normalizeData={{ id: "id", name: "name" }}
                  defaultItem={
                    product?.supplier && {
                      id: product.supplier.id,
                      name: product.supplier.name,
                    }
                  }
                  nullOpt={{ id: null, name: "Sin proveedor" }}
                  control={control}
                  label="Proveedor"
                  disabled={!adminRole}
                />
              )}

              {product?.type === "SERVICE" &&
                business?.configurationsKey.find(
                  (itm) => itm.key === "module_booking"
                )?.value === "true" && (
                  <article>
                    <div className="flex gap-x-2 items-center ">
                      <div className="flex-1">
                        <InputDuration
                          name="duration"
                          label="Duración"
                          control={control}
                          defaultValue={product?.duration}
                          disabled={!hasDuration}
                        />
                      </div>
                      <div className="mt-5">
                        <Toggle
                          name="hasDuration"
                          control={control}
                          title="Tiene duración"
                          defaultValue={product?.hasDuration}
                        />
                      </div>
                    </div>
                    <div className="block ">
                      <InputColor
                        name="color"
                        control={control}
                        label="Color "
                        defaultValue={product.color}
                      />
                    </div>
                  </article>
                )}

              <TextArea
                label="Descripción"
                name="description"
                placeholder="Breve descripción del producto"
                control={control}
                defaultValue={product?.description}
                disabled={!adminRole}
              />
            </div>
            <div className="absolute -bottom-14 right-0 flex justify-end pt-10 self-end">
              {adminRole && denyRoles(["AUDITOR"]) && (
                <Button name="Actualizar" color="slate-600" type="submit" />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Details;

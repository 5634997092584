/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  ClipboardDocumentListIcon,
  HomeModernIcon,
  PlusIcon,
  ReceiptPercentIcon, TruckIcon,
} from "@heroicons/react/24/outline";
import Breadcrumb from "../../../components/navigation/Breadcrumb";
import GenericTable, {
  DataTableInterface,
  FilterOpts,
} from "../../../components/misc/GenericTable";
import React, { createContext, useEffect, useState } from "react";
import {
  BasicType,
  SelectInterface,
} from "../../../interfaces/InterfacesLocal";
import Modal from "../../../components/modals/GenericModal";
import { useServerBilling } from "../../../api/useServerBilling";
import {
  formatAdress,
  formatCurrency,
  formatDateForTable,
  generatePdf,
} from "../../../utils/helpers";
import Paginate from "../../../components/misc/Paginate";
import OrderStatusBadge from "../../../components/misc/badges/OrderStatusBadge";
import { WizzardRegisterBilling } from "./registers/WizzardRegisterBilling";
import { RegisterDetailsContainer } from "./RegisterDetailsContainer";
import { FaClipboard, FaMoneyBill, FaRegFilePdf } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import {
  Control,
  SubmitHandler,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFieldArrayUpdate,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {Regions, RegisterBillingInterface} from "../../../interfaces/ServerInterfaces";
import { parseISO } from "date-fns";
import {useAppSelector} from "../../../store/hooks";
import { translateOrderOrigin } from "../../../utils/translate";
import Input from "../../../components/forms/Input";
import Button from "../../../components/misc/Button";
import { BsFiletypeXlsx } from "react-icons/bs";
import { toast } from "react-toastify";

import { Tooltip } from "react-tooltip";
import BillingOrderListPdf from "../../../reports/BillingOrderListPdf";
import { MdOutlinePriceChange } from "react-icons/md";
import useServer from "../../../api/useServerMain";
import AsyncComboBox from "../../../components/forms/AsyncCombobox";
import TextArea from "../../../components/forms/TextArea";
import Select from "../../../components/forms/Select";
import ScrollTypeFilter from "../../../components/misc/ScrollTypeFilter";

export interface RegisterContextInterface {
  currentStep: number;
  setCurrentStep: Function;
  watch: UseFormWatch<Record<string, any>>;
  getValues: UseFormGetValues<Record<string, any>>;
  setValue: UseFormSetValue<Record<string, any>>;
  reset: UseFormReset<Record<string, any>>;
  control: Control;
  handleSubmit: UseFormHandleSubmit<Record<string, any>>;
  trigger: UseFormTrigger<Record<string, any>>;
  fields: Record<string, any>[];
  append: UseFieldArrayAppend<any, "products">;
  remove: UseFieldArrayRemove;
  clearArrayOfProducts: UseFieldArrayReplace<any, "products">;
  update: UseFieldArrayUpdate<any, "products">;
  clearErrors: UseFormClearErrors<Record<string, any>>;
  isFetching?: boolean;
  isSubmit?: boolean;
  isLoading: boolean;
  isFetchingAux: boolean;
  orderById?: RegisterBillingInterface | null;
  convertPreBillToBill: Function;
  refundBillingOrder: Function;
  getOrderBillingById: Function;
  updateSingleOrderState: Function;
  editBilling: Function;
  editBillingDetails: Function;
  addNewBilling: Function;
  addNewPreBilling: Function;
  cancelOrder: Function;
  close: Function;
  submit?: boolean;
  setSubmit?: Function;
  deletePartialPayment?: Function;
  setIsLoading?: Function;
  openPayModal?: boolean;
  setOpenPayModal?: Function;
  setDetailsRegisterModal?: Function;
  TransformPreReceiptIntoReceipt?: Function;
  shippingRegions: Regions[];
  register: any
}

export const RegisterContext = createContext<Partial<RegisterContextInterface>>(
  {}
);

const AllRegistersList = () => {
  // Hooks
  const {
    getAllRegisterBillingList,
    registerBillingList,
    isFetching,
    registerPaginate,
    updateOrderListLocally,
    addNewPreBilling,
    addNewBilling,
    editBilling,
    editBillingDetails,
    isLoading,
    cancelOrder,
    getOrderBillingById,
    orderById,
    updateSingleOrderState,
    convertPreBillToBill,
    refundBillingOrder,
    deletePartialPayment,
    isFetchingAux,
    setIsLoading,
    exportExcelOrders,
    TransformPreReceiptIntoReceipt,
      getAllDeliverers
  } = useServerBilling();

  const { denyRoles } = useServer();

  const {shippingRegions} = useAppSelector((state) => state.nomenclator)
  const { areas } = useAppSelector((state) => state.nomenclator);

  const [filter, setFilter] = useState<BasicType>({
    page: 1,
  });
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [wizzardRegisterBillingModal, setWizzardRegisterBillingModal] =
    useState(false);
  const [showDispatchSelectedModal, setShowDispatchSelectedModal] = useState(false)
  const [detailsRegisterModal, setDetailsRegisterModal] = useState<{
    state: boolean;
    id: number | null;
  }>({ state: false, id: null });
  //---------Control for pay modal in register and billing --->
  const [openPayModal, setOpenPayModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);

  const { business } = useAppSelector((state) => state.init);
  useEffect(() => {
    getAllDeliverers();
  }, []);

  useEffect(() => {
    if (!detailsRegisterModal.state) {
      setOpenPayModal(false);
    }
  }, [detailsRegisterModal]);

  const {
    trigger,
    getValues,
    control,
    watch,
    setValue,
    reset,
    clearErrors,
    handleSubmit,
      register
  } = useForm();
  const { append, fields, remove, update, replace } = useFieldArray({
    name: "products",
    control,
  });
  const [currentStep, setCurrentStep] = useState<number>(0);

  const currencies =
    business?.availableCurrencies.map((item) => item.code) ?? [];

  const contextValues: RegisterContextInterface = {
    currentStep,
    setCurrentStep,
    control,
    watch,
    reset,
    setValue,
    fields,
    append,
    remove,
    update,
    trigger,
    getValues,
    clearErrors,
    isFetching,
    addNewBilling,
    addNewPreBilling,
    isLoading,
    editBilling,
    editBillingDetails,
    handleSubmit,
    orderById,
    cancelOrder,
    clearArrayOfProducts: replace,
    getOrderBillingById,
    convertPreBillToBill,
    updateSingleOrderState,
    refundBillingOrder,
    isSubmit,
    setSubmit,
    deletePartialPayment,
    close: () => setWizzardRegisterBillingModal(false),
    isFetchingAux,
    setIsLoading,
    setDetailsRegisterModal,
    setOpenPayModal,
    openPayModal,
    TransformPreReceiptIntoReceipt,
    shippingRegions: shippingRegions,
    register: register
  };
  // =================================================

  // BillingType
  const billingType: SelectInterface[] = [
    {
      id: "false",
      name: "Factura",
    },
    {
      id: "true",
      name: "Pre-factura",
    },
  ];
  // BillingStates
  const billingStates: SelectInterface[] = [
    {
      id: "BILLED",
      name: "Facturada",
    },
    {
      id: "PAYMENT_PENDING",
      name: "Pendiente de pago",
    },
    {
      id: "CREATED",
      name: "Creada",
    },
    {
      id: "OVERDUE",
      name: "Vencido",
    },
    {
      id: "REFUNDED",
      name: "Reembolsado",
    },
    {
      id: "CANCELLED",
      name: "Cancelado",
    },
    {
      id: "ACTIVE",
      name: "Activa",
    },
    {
      id: "CLOSED",
      name: "Cerrada",
    },
    {
      id: "DISPATCHED",
      name: "Despachada",
    },
    {
      id: "RECEIVED",
      name: "Procesando",
    },
    {
      id: "COMPLETED",
      name: "Completada",
    },
    {
      id: "WAITING",
      name: "En espera",
    },
    {
      id: "WITH_ERRORS",
      name: "Con errores",
    },
    {
      id: "IN_TRANSIT",
      name: "En tránsito",
    },
    {
      id: "DELIVERED",
      name: "Entregada",
    },
  ];
  // Billing Origin
  const billingOrigin: SelectInterface[] = [
    {
      id: "admin",
      name: "Administración",
    },
    {
      id: "pos",
      name: "Punto de venta",
    },
    {
      id: "shop",
      name: "Tienda online",
    },
    {
      id: "marketplace",
      name: "Marketplace",
    },
  ];
  // Table Titles
  const tableTitle = [
    "Tipo",
    "No.",
    "Cliente",
    "Estado",
    "Emisión",
    "Fecha de pago",
    "Origen",
    "Importe",
    "Total productos",
    "Fecha de entrega",
    "Dirección de entrega",
    "Punto de venta",
    "Íconos",
  ];

  const tableData: DataTableInterface[] = [];
  registerBillingList &&
    registerBillingList?.map((order) => {
      tableData.push({
        rowId: order.id,
        payload: {
          Tipo: (
            <div className="flex justify-start flex-row">
              {!order?.isPreReceipt ? (
                <div className="flex gap-2">
                  <FaMoneyBill size={20} className="text-green-500" />
                  {order.partialPayments?.length > 0 &&
                    order.status === "PAYMENT_PENDING" && (
                      <TooltipG
                        content="Pago parcial"
                        anchorSelect="partialPayment"
                      >
                        {/* <FaMoneyBill1Wave size={20} className="text-green-400" /> */}
                        <GiPayMoney size={25} className="text-yellow-500" />
                      </TooltipG>
                    )}

                  <p>Factura</p>
                </div>
              ) : (
                <div className="flex gap-2">
                  <FaClipboard size={20} className="text-amber-500" />
                  <p>Pre-Factura</p>
                </div>
              )}
            </div>
          ),
          "No.": (
            <div className="flex justify-start">
              {order?.isPreReceipt
                ? `${parseISO(order.createdAt).getFullYear()}/${order.preOperationNumber
                }`
                : `${parseISO(order.createdAt).getFullYear()}/${order.operationNumber
                }`}
            </div>
          ),
          Cliente: (
            <div className="flex justify-start flex-row">
              {order?.client?.firstName || order?.client?.lastName
                ? `${order?.client?.firstName ?? ""} ${order?.client?.lastName ?? ""
                }`
                : order?.client?.email ?? "Invitado"}
            </div>
          ),
          Estado: (
            <div className="flex flex-col">
              {
                <div>
                  <OrderStatusBadge status={order?.status} />
                </div>
              }
            </div>
          ),
          Emisión: (
            <div className="flex flex-col">
              {formatDateForTable(order?.createdAt)}
            </div>
          ),
          "Fecha de pago": (
            <div className="flex flex-col">
              {order?.status === "BILLED" && order?.paidAt
                ? formatDateForTable(order?.paidAt as string)
                : "-"}
            </div>
          ),
          Origen: (
            <div className="flex flex-col">
              {translateOrderOrigin(order.origin)}
            </div>
          ),
          Importe: (
            <p className="flex flex-col">
              {order.totalToPay?.map((total) => (
                <p key={total?.codeCurrency}>
                  <span className="text-sm">
                    {formatCurrency(total.amount, total.codeCurrency)}
                  </span>{" "}
                </p>
              ))}
            </p>
          ),
          "Total productos": (
              <p className="flex flex-col">
                {order?.totalItems ?? "-"}
              </p>
          ),
          "Fecha de entrega": order?.deliveryAt? formatDateForTable(order.deliveryAt) : "-",
          //@ts-ignore
          "Dirección de entrega": formatAdress({
            municipality: order.shipping?.municipality?.name,
            province: order.shipping?.province?.name,
            street1: order.shipping?.street_1,
            street2: order.shipping?.street_2,
          }),
          "Punto de venta": <p>{order?.areaSales?.name ?? ""}</p>,
          "Íconos": (
            <div className="inline-flex gap-2">
              {order.discount === 100 ? (
                <ReceiptPercentIcon
                  className="h-6"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Descuento 100%"
                />
              ) : order.houseCosted ? (
                <HomeModernIcon
                  className="h-5 text-green-600"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Consumo casa"
                />
              ) : order.isPreDispatched ? (
                <TruckIcon
                    className="h-5 text-amber-600"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Predespachado"
                />
              ) : order.currenciesPayment !== undefined ? order.currenciesPayment.some(
                  (item) => item.paymentWay === "TRANSFER"
                ) : (
                  ""
                )}
              {order.modifiedPrice && (
                <MdOutlinePriceChange
                  style={{ height: "2rem", width: "1.4rem" }}
                />
              )}
              {order.modifiedPrice && (
                  <MdOutlinePriceChange
                      style={{ height: "2rem", width: "1.4rem" }}
                  />
              )}

              <Tooltip place="top" id="my-tooltip" />
            </div>
          ),
        },
      });
    });

  const tableActions = [

    // {
    //   title: "Exportar a Pdf",
    //   icon: <FaRegFilePdf className="h-5 text-gray-500" />,
    //   action: () =>
    //     reportDownloadHandler(
    //       "Ordenes con productos",
    //       "order_list",
    //       business!,
    //       registerBillingList,
    //       { page: { orientation: "landscape" } }
    //     ),
    // },
    {
      title: "Predespachar selección",
      icon: <TruckIcon className="h-5 text-gray-500" />,
      action: () => setShowDispatchSelectedModal(true),
    },
    {
      title: "Exportar a PDF",
      icon: <FaRegFilePdf className="h-5 text-gray-500" />,
      action: () => {
        const toastId = toast.info("Creando Reporte...", {
          isLoading: true,
          autoClose: false,
        });
        generatePdf(
          <BillingOrderListPdf
            orders={registerBillingList}
            //@ts-ignore
            filters={{ dateFrom: filter.dateFrom, dateTo: filter.dateTo }}
            business={business}
          />
        )
          .then(() => {
            toast.update(toastId, {
              isLoading: false,
              autoClose: 5000,
              render: "Reporte listo para descargar",
            });
          })
          .catch(() => {
            toast.update(toastId, {
              type: "error",
              isLoading: false,
              autoClose: 5000,
              render: "Ocurrió un error creando el Reporte",
            });
          });
      },
    },
    {
      title: "Exportar a Excel",
      icon: <BsFiletypeXlsx />,
      action: () => {
        if (filter.dateFrom !== undefined) {
          setExportModal(true);
        } else {
          toast.error("Por favor defina un rango de fecha de emisión");
        }
      },
    },
    // {
    //   title: "Importar a Excel",
    //   icon: <FaRegFileExcel className="h-5 text-gray-500" />,
    //   // action: () => setExportModal(true),
    // },
  ];


  if (denyRoles(["AUDITOR"])) {
    tableActions.unshift(
      {
        title: "Nuevo registro",
        icon: <PlusIcon className="h-5 text-gray-500" />,
        action: () => setWizzardRegisterBillingModal(true),
      },
    )
  }
  // const searching = {
  //   action: (searchNumber: string | null) =>
  //     setFilter(searchNumber ? { searchNumber } : { page: 1 }),
  //   placeholder: "Buscar por número de operación",
  // };
  const {deliverymans} = useAppSelector(
      (state) => state.nomenclator!
  );
  const delivererSelect: SelectInterface[] = deliverymans?.filter((item) => item.roles?.some((rol) => rol.name === "Repartidor"))
      .map((item) => {
        return {
          id: item.id,
          name: item.displayName
        }
      }) ?? []
  const filterCodeDatePickerRangeCreatedAt = [
    {
      filterCode: "dateFrom",
      name: "Desde",
      isUnitlToday: false,
      includingTime: true,
    },
    {
      filterCode: "dateTo",
      name: "Hasta",
      isUnitlToday: true,
      includingTime: true,
    },
  ];

  const filterCodeDatePickerRangePaymentDL = [
    {
      filterCode: "paymentDeadlineFrom",
      name: "Desde",
      isUnitlToday: false,
      includingTime: true,
    },
    {
      filterCode: "paymentDeadlineTo",
      name: "Hasta",
      isUnitlToday: false,
      includingTime: true,
    },
  ];

  const filterCodePriceRange = [
    {
      name: "Desde",
      filterCode: "minTotalToPay",
    },
    {
      name: "Hasta",
      filterCode: "maxTotalToPay",
    },
    {
      name: "Moneda",
      filterCode: "currency",
      currencies: currencies,
    },
  ];

  const areaSalesSelector: SelectInterface[] = areas
    .filter((item) => item.type === "SALE")
    .map((area) => ({
      id: area.id,
      name: area.name,
    }));

  const billedRange = [
    {
      filterCode: "billFrom",
      name: "Desde",
      isUnitlToday: true,
      includingTime: true,
    },
    {
      filterCode: "billTo",
      name: "Hasta",
      isUnitlToday: true,
      includingTime: true,
    },
  ];

  const availableFilters: FilterOpts[] = [
    {
      format: "number",
      filterCode: "operationNumber",
      name: "No.Factura",
    },
    {
      format: "number",
      filterCode: "preOperationNumber",
      name: "No.Pre Factura",
    },
    {
      format: "input",
      filterCode: "name",
      name: "Título de Factura",
    },
    {
      format: "select",
      name: "Tipo",
      filterCode: "isPreReceipt",
      data: billingType,
    },
    {
      name: "Punto de venta",
      filterCode: "areaSalesId",
      format: "select",
      data: areaSalesSelector,
    },
    {
      format: "select",
      name: "Origen",
      filterCode: "origin",
      data: billingOrigin,
    },
    {
      name: "Cliente",
      filterCode: "clientId",
      format: "select",
      asyncData: {
        url: "/customer",
        dataCode: ["firstName", "lastName"],
        idCode: "id",
      },
    },
    {
      name: "Comercial",
      filterCode: "managedById",
      format: "select",
      asyncData: {
        url: "/security/users",
        dataCode: ["displayName"],
        idCode: "id",
      },
    },
    {
      format: "select",
      name: "Mensajero / Repartidor",
      filterCode: "shippingById",
      data: delivererSelect,
    },
    {
      format: "multiselect",
      name: "Estado",
      filterCode: "status",
      data: billingStates,
    },
    {
      name: "Provincia",
      filterCode: "provinceId",
      format: "select",
      asyncData: {
        url: "/public/provinces",
        dataCode: ["name"],
        idCode: "id",
      },
    },
    {
      name: "Municipio",
      filterCode: "municipalityId",
      format: "select",
      asyncData: {
        url: "/public/municipalities",
        dataCode: ["name"],
        idCode: "id",
      },
    },
    {
      format: "datepicker",
      filterCode: "deliveryAt",
      name: "Fecha de entrega",
    },
    {
      format: "datepicker-range",
      filterCode: "bill",
      name: "Fecha de facturación",
      datepickerRange: billedRange,
      includingTime: true,
    },
    {
      format: "datepicker-range",
      filterCode: "dateFrom",
      name: "Fecha de emisión",
      datepickerRange: filterCodeDatePickerRangeCreatedAt,
      includingTime: true,
    },
    {
      format: "datepicker-range",
      filterCode: "paymentDeadlineFrom",
      name: "Fecha de vencimiento",
      datepickerRange: filterCodeDatePickerRangePaymentDL,
      includingTime: true,
    },
    {
      format: "price-range",
      filterCode: "toPay",
      name: "Rango de importe",
      priceRange: filterCodePriceRange,
    },
    {
      format: "boolean",
      name: "Facturas con efectivo",
      filterCode: "withCashRegisterOperations",
    },
    {
      format: "boolean",
      name: "Todos los datos",
      filterCode: "all_data",
    },
    // {
    //   format: "datepicker",
    //   filterCode: "importFrom",
    //   name: "Importe desde",
    // },
    // {
    //   format: "datepicker",
    //   filterCode: "importTo",
    //   name: "Importe hasta",
    // },

    // {
    //   format: "select",
    //   name: "Moneda",
    //   filterCode: "currency",
    //   data: currenciesList
    // },
  ];

  const filterAction = (
    data: Record<string, string | number | boolean> | null
  ) => {
    data ? setFilter({...data}) : setFilter({ page: 1 });
  };

  useEffect(() => {
    getAllRegisterBillingList({...filter, includeShipping: true});
  }, [filter]);

  const closeWizzardRegisterBillingModal = () => {
    setWizzardRegisterBillingModal(false);
    setCurrentStep(0);
    replace([]);
    reset();
  };

  return (
    <div>
      <Breadcrumb
        icon={<ClipboardDocumentListIcon className="h-6 text-gray-500" />}
        paths={[{ name: "Facturación" }, { name: "Registros" }]}
      />
      <ScrollTypeFilter
          title="Filtrar registros"
          items={billingStates}
          current={filter?.status ?? null}
          onChange={(item: string | number | null) =>
              setFilter({
                ...filter,
                status: item,
              })
          }
      />
      <GenericTable
        tableTitles={tableTitle}
        // searching={searching}
        tableData={tableData}
        actions={tableActions}
        showSpecificColumns={true}
        defaultColumnsShowed={
          tableTitle.map((item, index) => ({
            id: index,
            name: item
          })).filter(item => ["Tipo", "No.", "Cliente", "Estado", "Emisión", "Importe", "Íconos"].includes(item.name))
        }
        syncAction={{
          action: () => getAllRegisterBillingList(filter),
          loading: isFetching,
        }}
        loading={isFetching}
        filterComponent={{ availableFilters, filterAction }}
        rowAction={(id: number) =>
          setDetailsRegisterModal(() => {
            setIsLoading(true);
            return { state: true, id: id };
          })
        }
        paginateComponent={
          <Paginate
            action={(page: number) => setFilter({ ...filter, page })}
            data={registerPaginate}
          />
        }
      />
      <RegisterContext.Provider value={contextValues}>
        {/* NewRegisterModal */}
        {wizzardRegisterBillingModal && (
          <Modal
            state={wizzardRegisterBillingModal}
            close={closeWizzardRegisterBillingModal}
            size="l"
          >
            <WizzardRegisterBilling
              close={() => closeWizzardRegisterBillingModal()}
            />
          </Modal>
        )}

        {/* Details Register Modal */}
        {detailsRegisterModal.state && (
          <Modal
            state={detailsRegisterModal.state}
            close={() => setDetailsRegisterModal({ state: false, id: null })}
            size="l"
          >
            <RegisterDetailsContainer
              id={detailsRegisterModal?.id!}
              updateState={updateOrderListLocally}
              closeModalDetails={() =>
                setDetailsRegisterModal({ state: false, id: null })
              }
            />
          </Modal>
        )}
      </RegisterContext.Provider>

      {showDispatchSelectedModal &&
          <Modal state={showDispatchSelectedModal} close={setShowDispatchSelectedModal}>
            <DispatchSelectedModal
                registerBillingList={registerBillingList}
                close={() => setShowDispatchSelectedModal(false)}
            />
          </Modal>
      }

      {exportModal && (
        <Modal state={exportModal} close={setExportModal}>
          <ExportModalContainer
            exportAction={exportExcelOrders}
            filters={filter}
            close={() => setExportModal(false)}
            isLoading={isLoading}
          />
        </Modal>
      )}
    </div>
  );
};
export default AllRegistersList;

const ExportModalContainer = ({
  exportAction,
  filters,
  close,
  isLoading,
}: {
  exportAction: Function;
  filters: any;
  close: Function;
  isLoading: boolean;
}) => {
  const { control, handleSubmit } = useForm();
  const submit: SubmitHandler<Record<string, string>> = (data) => {
    exportAction(
      { dateFrom: filters.dateFrom, dateTo: filters.dateTo, all_data: true },
      data.name,
      close
    );
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Input
        name="name"
        control={control}
        label="Nombre del archivo .xlsx"
        rules={{ required: "Requerido *" }}
      />
      <div className="flex justify-end py-2">
        <Button
          color="slate-600"
          name="Aceptar"
          type="submit"
          loading={isLoading}
          disabled={isLoading}
        />
      </div>
    </form>
  );
};

interface TooltipProps {
  content: string;
  anchorSelect: string; // Identificador único para el tooltip
  children: React.ReactNode;
}

export const TooltipG: React.FC<TooltipProps> = ({
  children,
  content,
  anchorSelect,
}) => {
  return (
    <>
      <div id={anchorSelect}>{children}</div>
      <Tooltip anchorSelect={`#${anchorSelect}`} content={content}></Tooltip>
    </>
  );
};

const DispatchSelectedModal = ({close, registerBillingList}: {close: Function, registerBillingList: RegisterBillingInterface[]}) => {
  const { areas } = useAppSelector((state) => state.nomenclator);
  const areasSelect: SelectInterface[] = areas.filter(item => item.type === "SALE").map(item => ({id: item.id, name: item.name}))
  const {handleSubmit, control} = useForm()
  const {sendPredispatchSelected, isLoading} = useServerBilling();
  const comp = (amountDispatches: number, amountOrdersMove: number) => (
      <div>
        Predespachos enviados correctamente <br />
        Prefacturas procesadas: {amountDispatches} <br />
        Productos predespachados: {amountOrdersMove}
      </div>
  )
  const onSubmit: SubmitHandler<Record<string, string | number>> = (data) => {
    const {saleArea} = data;
    sendPredispatchSelected(Number(saleArea), registerBillingList.map(item => item.id), comp)
  }
    return (
      <div>
        <h2 className="text-slate-600 font-semibold text-xl  text-left mb-2">
          {"Predespachar selección"}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col'}>
          <div className="grid grid-cols-1 gap-5 mt-5">

            <Select
                name="saleArea"
                label="Área de venta"
                control={control}
                rules={{required: "Este campo es requerido"}}
                data={areasSelect}
            />

            <h3 className={'text-sm font-medium text-gray-700  first-letter:uppercase'}>
              Cantidad de prefacturas seleccionadas: {registerBillingList.length}
            </h3>

          </div>

          <div className="w-1/2 mt-8 self-end">
            <Button
                color="slate-600"
                type="submit"
                name={"Aceptar"}
                loading={isLoading}
                disabled={isLoading}
                full
            />
          </div>

        </form>
      </div>
  )
}
import React, {useContext, useEffect, useState} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "../../../components/forms/Input";
import Button from "../../../components/misc/Button";
import { BasicType } from "../../../interfaces/InterfacesLocal";
import { EditClientCtx } from "./EditOnlineClientContainer";
import AsyncComboBox from "../../../components/forms/AsyncCombobox";
import GenericTable, {DataTableInterface} from "../../../components/misc/GenericTable";
import {formatCalendar} from "../../../utils/helpers";
import {translateRegWay} from "../../../utils/translate";
import useServerOnlineClients from "../../../api/useServerOnlineClients";
import {ActionBtnInterface} from "../../../components/misc/GenericList";
import {BsFiletypeXlsx} from "react-icons/bs";
import {PlusIcon} from "@heroicons/react/24/outline";
import Modal from "../../../components/misc/GenericModal";
import TextArea from "../../../components/forms/TextArea";
import {AddressInterface, FixedCost} from "../../../interfaces/ServerInterfaces";
import {TrashOutline} from "heroicons-react";

const EditAddressData = () => {
  const { client, editClient } = useContext(EditClientCtx);
  const serverOnlineClients = useServerOnlineClients()
  const {getAllAddress, allAddress, isLoading} = serverOnlineClients
  const [addAddressModal, setAddAddressModal] = useState(false)
  const tableTitles = ["Calle principal", "Calle secundaria", "Localidad", "País",  "Provincia", "Municipio", "Código postal", "Descripción"];
  const [addressToEdit, setAddressToEdit] = useState<AddressInterface | undefined>(undefined)
  useEffect(() => {
    if (client)
      getAllAddress!(client.id)
  }, []);

  const tableData: DataTableInterface[] = allAddress.map((item) => ({
      rowId: item.id,
      payload: {
        "Calle principal": item.street_1 ?? "-",
        "Calle secundaria": item?.street_2 ?? "-",
        "Localidad": item?.city ?? "-",
        "País": item?.country?.name ?? "-",
        "Provincia": item?.province?.name ?? "-",
        "Municipio": item?.municipality?.name ?? "-",
        "Código postal": item?.postalCode ?? "-",
        "Descripción": item?.description ?? "-",

      },
  }));

  const tableActions = [
    {
      icon: <PlusIcon className="h-5" />,
      title: "Nueva dirección",
      action: () => setAddAddressModal(true),
    },
  ];
  const rowAction = (id: number) => {
    const current = allAddress.find((item) => item.id === id);
    if (current) {
      setAddressToEdit(current);
      setAddAddressModal(true);
    }
  };

  return (
    <>
          <GenericTable
              tableTitles={tableTitles}
              tableData={tableData}
              actions={tableActions}
              rowAction={rowAction}
              loading={isLoading}
          />
      {addAddressModal &&
          <Modal state={addAddressModal} close={() => {
            setAddAddressModal(false)
            setAddressToEdit(undefined)
          }} size={"m"}>
            <NewAddressModal
                serverOnlineClients={serverOnlineClients}
                close={() => {
                     setAddAddressModal(false)
                     setAddressToEdit(undefined)
                   }}
                addressToEdit={addressToEdit}/>
          </Modal>

      }
    </>
  );
}

export default EditAddressData;

interface ModalProps {
  serverOnlineClients: {
    addAddress: (idClient: number, data: Record<string, string | number | boolean>, callback: Function) => Promise<void>
    getAllAddress: (idClient: number, filter?: (BasicType | undefined)) => Promise<void>
    editAddress: (id: number, data: Record<string, string | number | boolean>, callback: Function) => Promise<void>
    deleteAddress: (id: number, callback: Function) => Promise<void>
  },
  close: () => void
  addressToEdit?: AddressInterface
}

function NewAddressModal({serverOnlineClients, close, addressToEdit}: ModalProps) {
  const {control, watch, getValues, handleSubmit} = useForm()
  const {addAddress, getAllAddress, editAddress, deleteAddress} = serverOnlineClients
  const { client } = useContext(EditClientCtx);

  const onSubmit: SubmitHandler<Record<string, number>> = (data) => {
    if (!addressToEdit) {
      addAddress(client!.id, data, () => {
        if (client)
          getAllAddress(client.id)
        close()
      })
    }else{
      editAddress(addressToEdit!.id, data, () => {
        getAllAddress(client!.id)
        close()
      })
    }
  };


  return (
      <form className={'size-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'flex justify-between w-full pe-6'}>
          <h2 className={'text-gray-600 text-xl font-bold'}>
            {!addressToEdit? "Nueva dirección" : "Editar dirección"}
          </h2>
          {!!addressToEdit && (
              <Button
                  color="red-400"
                  icon={
                    <TrashOutline
                        className="h-5 text-gray-500"
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        stroke={"#f87171"}/>
                  }
                  action={() => {
                    deleteAddress(addressToEdit.id, () => {
                      close()
                    })
                  }}
                  outline
              />
          )}
        </div>

        <div className="grid grid-cols-2 gap-2 mt-5">
          <div>
            <Input
                label="Calle principal"
                name="street_1"
                control={control}
                defaultValue={!!editAddress ? addressToEdit?.street_1 : undefined}
            />
          </div>

          <div>
            <Input
                label="Calle secundaria"
                name="street_2"
                control={control}
                defaultValue={!!addressToEdit ? addressToEdit?.street_2  : undefined}
            />
          </div>

          <div>
            <AsyncComboBox
                label="País  (*)"
                name="countryId"
                control={control}
                // id : cuba
                dataQuery={{
                  url: "/public/countries",
                  defaultParams: {id: 54, all_data: true},
                }}
                defaultValue={!!addressToEdit? addressToEdit?.country?.id : 54}
                defaultItem={!!addressToEdit ? {
                  id: addressToEdit?.country?.id,
                  name: addressToEdit?.country?.name
                } : {
                  id: 54,
                  name: "Cuba"
                }}
                normalizeData={{id: "id", name: "name"}}
            />
          </div>

          <div>
            <AsyncComboBox
                name="provinceId"
                label="Provincia"
                control={control}
                dataQuery={{url: "/public/provinces"}}
                normalizeData={{id: "id", name: "name"}}
                dependendValue={{
                  countryId:
                      watch!("countryId") ??
                      getValues!("countryId"),
                }}
                defaultValue={!!addressToEdit && addressToEdit?.province?.id}
                defaultItem={!!addressToEdit ? {
                  id: addressToEdit?.province?.id,
                  name: addressToEdit?.province?.name
                } : undefined}
            />
          </div>

          <div>
            <AsyncComboBox
                name="municipalityId"
                label="Municipio"
                control={control}
                dataQuery={{url: "/public/municipalities"}}
                normalizeData={{id: "id", name: "name"}}
                dependendValue={{
                  provinceId:
                      watch!("provinceId") ??
                      getValues!("provinceId"),
                }}
                defaultValue={!!addressToEdit && addressToEdit?.municipality?.id}
                defaultItem={!!addressToEdit ? {
                  id: addressToEdit?.municipality?.id,
                  name: addressToEdit?.municipality?.name
                } : undefined}

            />
          </div>
          <div>
            <Input
                label="Código postal"
                name="postalCode"
                control={control}
                defaultValue={!!addressToEdit ? addressToEdit?.postalCode : undefined}
            />
          </div>
          <div>
            <Input
                label="Localidad"
                name="city"
                control={control}
                defaultValue={!!addressToEdit ? addressToEdit?.city : undefined}
            />
          </div>
        </div>
        <div>
          <TextArea
              label="Descripción"
              name="description"
              control={control}
              defaultValue={!!addressToEdit? addressToEdit?.description : undefined}
          />
        </div>
        <div className={'mt-10 flex justify-end'}>
          <Button
              name={!addressToEdit ? "Añadir" : "Editar"}
              type={"submit"}
              color={"slate-600"}
          />
        </div>
      </form>
  )
}

import { useState } from 'react';
import { useForm } from "react-hook-form";
import DateInput from "../../../components/forms/DateInput";
import Button from "../../../components/misc/Button";
import AsyncComboBox from "../../../components/forms/AsyncCombobox";
import useServerBankAccount from "../../../api/useServerBankAccount";
import ReportBankAccountOperations from './ReportBankAccountOperations';
import { SubmitHandler } from "react-hook-form";
import Modal from "../../../components/misc/GenericModal";

const BankAccountOperations = () => {
  const { control, handleSubmit } = useForm<Record<string, string | number>>();
  const { getAllBankAccountOperations, allBankAccountOperation, setAllBankAccountOperation, isLoading } = useServerBankAccount();
      const [showReportDataModal, setShowReportDataModal] = useState<boolean | null>();

  const exportAccountBalance = async (data: any) => {
    try {
      setShowReportDataModal(true);
      const response = await getAllBankAccountOperations(data?.accountId, { dateFrom: data?.dateFrom, dateTo: data?.dateTo }, true);
      setAllBankAccountOperation(response.data.items);
    } catch (error) {
      console.error('Error al exportar el balance:', error);
    }
  };

      
  const onSubmit: SubmitHandler<Record<string, string | number>> = (data) => {
    exportAccountBalance(data);
  };

  return (
    <>
      <h2 className="text-xl font-semibold mb-6">Listado de operaciones</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-2"> 
          <DateInput
                includeTime={true}
                label={"Desde"}
                control={control}
                untilToday={true}
                defaultValue={null}
                name={"dateFrom"}
                rules={{ required: "Este campo es requerido" }}
            />
            <DateInput
                includeTime={true}
                label={"Hasta"}
                control={control}
                untilToday={true}
                defaultValue={null}
                name={"dateTo"}
                rules={{ required: "Este campo es requersido" }}
            />         
        </div>
        <div className="grid grid-cols-1 gap-2 mt-3">
          <AsyncComboBox
            dataQuery={{
              url: "/administration/bank/account",
              defaultParams: { page: 1 },
            }}
            normalizeData={{ id: "id", name: ["name"] }}
            name="accountId"
            control={control}
            label="Cuentas *"
            placeholder="Seleccione un cuenta"
            rules={{required:true}}
          />
        </div>

        <div className="mt-3 w-full flex justify-end items-center gap-2">          
          <Button
              color={"slate-600"}
              type="submit"
              name="Buscar"
              textColor={"white"}
          />
      </div>
      </form>
      {!!showReportDataModal && (
          <Modal
              state={showReportDataModal}
              close={() => setShowReportDataModal(null)}
              size="l"
          >
              <ReportBankAccountOperations
                  allBankAccountOperation={allBankAccountOperation}
                  isLoading={isLoading}
              />
          </Modal>
      )}
    </>
  );
}

export default BankAccountOperations;

import { useState } from 'react';
import GenericTable, {
  DataTableInterface,
} from '../../../components/misc/GenericTable';
import { formatCalendar, formatCurrency } from "../../../utils/helpers";
import { FaRegFilePdf } from 'react-icons/fa';
import { HomeIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { exportExcel } from "../../../utils/helpers";
import Modal from "../../../components/misc/GenericModal";
import Input from "../../../components/forms/Input";
import Button from "../../../components/misc/Button";
import { SubmitHandler, useForm } from "react-hook-form";

const ReportBankAccountOperations = ({
    allBankAccountOperation,
    isLoading
}: any) => {
      const [exportModal, setExportModal] = useState(false);
      const getConceptValue = (item: any) => {
        const concept: any = {
          "move": <HomeIcon className="h-5" title="Movimiento interno" />,
          "balance": <CurrencyDollarIcon className="h-5" title="Balance" />,
        }
        if(["move", "balance"].includes(item?.operation)){  
          return concept[item?.operation];
        }
        return item?.accountTag ? item?.accountTag?.name : "---";
      }
    
      const titles: string[] = [
        "Concepto",
        "Fecha",
        "Monto",
        "Subcuenta",
        "Hecho por",
        "No.Transacción",
        "Descripción",
      ];
    
    const displayData: Array<DataTableInterface> = [];
        allBankAccountOperation?.forEach((item: any) => {
        if (item.operation !== "balance") {
            return displayData.push({
            rowId: item.id,
              payload: {
                  Concepto: getConceptValue(item),
                  Fecha: formatCalendar(item.registeredAt),
                  Monto: (
                  <span
                      className={`py-1 px-2 rounded-full text-sm ${
                      item.amount?.amount! > 0 ? "text-green-700" : "text-red-700"
                      }  font-semibold text-center`}
                  >
                      {formatCurrency(
                      item?.amount?.amount ?? 0,
                      item?.amount?.codeCurrency
                      )}
                  </span>
                  ),
                  Subcuenta: item?.subAccount ? item?.subAccount?.name : "---",
                  "Hecho por": item?.madeBy?.displayName,
                  "No.Transacción": item.noTransaction,
                  Descripción: <p className="text-justify">{item.description}</p>,
              },
            });
        }    
    });

    const exportAction = (name: string) => {
        let dataToExport;
        const mapItem = (item: any) => ({
          Concepto: ["move", "balance"].includes(item?.operation) ? item?.operation : item?.accountTag ? item?.accountTag?.name : "---",
          Fecha: formatCalendar(item.registeredAt),
          Monto: formatCurrency(
            item?.amount?.amount ?? 0,
            item?.amount?.codeCurrency
          ),
          Subcuenta: item?.subAccount ? item?.subAccount?.name : "---",
          "Hecho por": item?.madeBy?.displayName,
          "No.Transacción": item.noTransaction,
          "Descripción": item.description
      });

      dataToExport = allBankAccountOperation.map(mapItem);
  
      exportExcel(dataToExport, name);
    }

    return (
      <>
      <GenericTable
          tableTitles={titles}
          tableData={displayData}
          loading={isLoading}
          actions={
          displayData.length
              ? [{
                  title: 'Exportar',
                  icon: <FaRegFilePdf className='h-5 text-gray-500' />,
                  action: () => setExportModal(true),
              }]
              : undefined
          } />
      {exportModal && (
          <Modal state={exportModal} close={setExportModal}>
            <ExportModalContainer
              exportAction={exportAction}
              close={() => setExportModal(false)}
            />
          </Modal>
        )
      }
      </>
    )
}

const ExportModalContainer = ({
  exportAction,
  close,
}: {
  exportAction: Function;
  close: Function;
}) => {
  const { control, handleSubmit } = useForm();
  const submit: SubmitHandler<Record<string, string>> = (data) => {
    exportAction(data.name);
    close();
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Input
        name="name"
        control={control}
        label="Nombre del archivo .xlsx"
        rules={{ required: "Requerido *" }}
      />
      <div className="flex justify-end py-2">
        <Button color="slate-600" name="Aceptar" type="submit" />
      </div>
    </form>
  );
};

export default ReportBankAccountOperations;